import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Impressum" />

    <section className="container">
      <h1 className="is-size-1">Impressum</h1>
      <address>
        Johannes Hofmann<br/>
        Küpferlingstr. 26<br/>
        83024 Rosenheim<br/>
        hi@tech-steward.de
      </address>
    </section>
  </Layout>
);